exports.linkResolver = (doc) => {
  const langPrefix = doc.lang === "en-us" ? "" : `/${doc.lang}`;

  switch (doc.type) {
    case "page":
      return `${langPrefix}/${doc.uid}`;

    default:
      if (!doc.uid) return "/";
      return `${langPrefix}/${doc.uid}`;
  }
};
